<template>
  <v-card>
    <v-card-title>スタッフの感情指数</v-card-title>
    <v-card-text>
      <staff-sentiments :sentiments="sentiments"></staff-sentiments>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { createStaffIfRequired, getSentiments } from '@/helpers/firebase-functions'

import staffSentiments from '@/components/staff/staffSentiments'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    staffSentiments
  },
  data: () => ({
    ownerId: '',
    ownerScenarioId: '',
    staffId: '',
    sentiments: []
  }),
  computed: {
    ...mapState('auth', ['providerData', 'uid']),
    ...mapGetters('auth', ['profile']),
    telno () {
      return this.providerData[0].phoneNumber
    }
  },
  methods: {
    async updateSentiments () {
      const { sentiments } = await getSentiments({
        ownerId: this.ownerId,
        ownerScenarioId: this.ownerScenarioId,
        staffId: this.staffId
      })

      this.sentiments = [{
        name: this.profile.givenName.concat(this.profile.firstName),
        telno: this.telno,
        sentiments
      }]
    }
  },
  async created () {
    this.now = new Date()
    this.ownerId = this.$route.params.ownerId
    this.ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    this.staffId = this.uid
    await createStaffIfRequired({
      ownerId: this.ownerId,
      ownerScenarioId: this.ownerScenarioId,
      staffId: this.staffId,
      data: { name: this.profile.givenName.concat(this.profile.firstName), telno: this.telno }
    })
    await this.updateSentiments()
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_STAFF_SENTIMENT)
  }
}
</script>

<template>
  <div>
    <v-row v-for="s in sentiments_" :key="s.name.concat(s.telno)">
      <v-col>
        <v-card>
          <v-card-subtitle>
            {{s.name}}
            <v-icon :color="s.mode.color">{{s.mode.icon}}</v-icon>
          </v-card-subtitle>
          <v-card-text>
            <v-sparkline
              :value="s.data"
              :gradient="gradient"
              :smooth="radius || false"
              :padding="padding"
              :line-width="width"
              :stroke-linecap="lineCap"
              :gradient-direction="gradientDirection"
              :fill="fill"
              :type="type"
              :auto-line-width="autoLineWidth"
              auto-draw
            ></v-sparkline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'

export default {
  name: 'staff-sentiments',
  props: {
    sentiments: {
      type: Array,
      required: false,
      default: function () { return [] }
    }
  },
  data: () => ({
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: 'round',
    gradient: ['#00E676', '#FFC400', '#FF1744'],
    gradientDirection: 'top',
    fill: false,
    type: 'trend',
    autoLineWidth: false,
    icons: [
      {
        icon: 'mdi-emoticon-frown-outline',
        color: '#FF1744'
      },
      {
        icon: 'mdi-emoticon-sad-outline',
        color: '#FF6E22'
      },
      {
        icon: 'mdi-emoticon-happy-outline',
        color: '#FFC400'
      },
      {
        icon: 'mdi-emoticon-outline',
        color: '#80D53B'
      },
      {
        icon: 'mdi-emoticon-excited-outline',
        color: '#00E676'
      }
    ],
    headers: [
      {
        text: '氏名',
        align: 'left',
        sortable: true,
        width: '160',
        value: 'name'
      },
      {
        text: '最近の感情',
        align: 'left',
        sortable: true,
        width: 'auto',
        value: 'sentiments'
      }
    ]
  }),
  computed: {
    sentiments_ () {
      // mode
      // const sentimentAverage = (data) => {
      //   const counts = [0, 0, 0, 0, 0]
      //   for (let i = 0; i < data.length; i++) {
      //     counts[data[i]]++
      //   }
      //   const max = Math.max(...counts)
      //   return counts.findIndex(n => n === max)
      // }

      // mean
      const sentimentAverage = (data) => {
        if (data.length === 0) {
          return 0
        }
        const sum = data.reduce((total, current) => total + current.index, 0)
        return Math.floor(sum / data.length)
      }

      const updated = this.sentiments.map(s => {
        const data = s.sentiments.map(s => s.index)
        const labels = s.sentiments.map(s => {
          const formatted = format(new Date(s.createdAt._seconds * 1000), 'yyyy/MM/dd HH:mm', { locale: ja })
          return formatted
        })

        return {
          name: s.name,
          data,
          labels,
          telno: s.telno !== null ? s.telno : '',
          mode: this.icons[sentimentAverage(s.sentiments)]
        }
      })

      return updated
    }
  },
  create () {
  }
}
</script>
